import { Typography } from "@mui/material";
import { Button, Col, Flex, Form, Input, Row } from "antd";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import HippaLogo from "../../assets/bg/hippa.png";
import LoadingModal from "../../components/LoadingModal";
import { useAppDispatch } from "../../hooks/redux-hooks";
import { setIsDemoPath } from "../../slices/appStateSlice";
import { login } from "../../slices/authSlice";
import { fetchUserData } from "../../slices/userSlice";
import { EventType, identifyUser, trackEvent } from "../../utils/analytics";
import RightSideAuth from "./auth-right-side.component";
import styles from "./auth.module.scss";

const Login = () => {
  const componentName = "login";
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async () => {
    const { email, password } = form.getFieldsValue();
    setIsLoading(true);
    if (email && password) {
      try {
        const loginSuccessful = await dispatch(
          login({
            email,
            password,
          }),
        ).unwrap();

        if (loginSuccessful) {
          dispatch(setIsDemoPath(false));
          dispatch(fetchUserData());
          identifyUser(email, { email });
          trackEvent(EventType.LOGIN, { email });
          const token = localStorage.getItem("token");
          if (!token) {
            setIsLoading(false);
            return;
          }
          navigate("/home");
        }
        setIsLoading(false);
      } catch (e) {
        console.error(e);
        setIsLoading(false);
        // replace below alet with sweetalert2 alert
        Swal.fire({
          title: "Incorrect email or password",
          icon: "error",
          text: "Incorrect email or password. Please try again.",
        });
      }
    } else {
      console.log("Email and password are required");
      setIsLoading(false);
      Swal.fire({
        title: "Password cannot be blank",
        icon: "error",
        text: "Please enter your password.",
      });
    }
  };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Row className="fullScreen">
        <Col
          sm={24}
          md={24}
          lg={12}
          className={styles[`${componentName}__left`]}
        >
          <Flex
            className={styles[`${componentName}__form`]}
            vertical
            justify="center"
          >
            <Typography
              fontWeight={500}
              fontSize={30}
              variant="h5"
              textAlign={"center"}
              margin={4}
            >
              Welcome to Nudge
            </Typography>
            <Form
              className={styles[`${componentName}__form-container`]}
              form={form}
              layout="vertical"
              onFinish={handleLogin}
              autoComplete="off"
            >
              <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: "Please input your Email" }]}
              >
                <Input placeholder="John@example.com" />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password placeholder="input password" type="password" />
              </Form.Item>
              <Flex
                justify="end"
                className={styles[`${componentName}__form-container-link`]}
              >
                <NavLink to={"/forgot-password"}>Forgot Password?</NavLink>
              </Flex>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            <Flex
              className={styles[`${componentName}__form-container-text`]}
              justify="center"
            >
              New User? &nbsp;<NavLink to={"/register"}>Register now</NavLink>
            </Flex>
            <div
              className={
                styles[`${componentName}__form-container-bottom-notification`]
              }
            >
              Nudge is HIPAA compliant and redacts PHI. <br /> Nudge removes any
              personally identifying data, including audio.
            </div>
            <img
              alt="Hippa"
              src={HippaLogo}
              className={styles[`${componentName}__side-image-hippa`]}
            />
          </Flex>
        </Col>
        <RightSideAuth />
      </Row>
    </>
  );
};

export default Login;
