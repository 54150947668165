import { Badge } from "antd";

type Props = {
  gender_type?: number | null | undefined;
};

export default function GenderType({ gender_type }: Props) {
  if (gender_type === 0)
    return <Badge count={"Neutral"} showZero className="badge-soap" />;
  if (gender_type === 1)
    return <Badge count={"He/Him"} showZero className="badge-soap" />;
  else return <Badge count={"She/Her"} showZero className="badge-soap" />;
}
