import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Flex, Select } from "antd";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { useNavigate } from "react-router-dom";
import {
  setCreateClientState,
  setCustomiseSectionState,
  setLoadingNotesState,
  setNotesViewVisible,
  setSelectedClient,
} from "../../slices/appStateSlice";
import { resetClientForm, setIsEditing } from "../../slices/clientSlice";
import { resetNotesDetails, setNotesDetails } from "../../slices/notesSlice";
import styles from "./home.module.scss";
import { useIsMobileView } from "../../hooks/ui-hook";

const ClientSelector = () => {
  const componentName = "home";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isMobile = useIsMobileView();

  const { clients } = useAppSelector((state) => state.client);
  const { isDemoPath } = useAppSelector((state) => state.appState);

  const selectClient = (clientId: number) => {
    const client = clients.find((c) => c.id === clientId);
    const clientUuid = client?.uuid;
    navigate(`/home/clients/${clientUuid}`);
  };

  const clearSelection = () => {
    if (!isDemoPath) {
      dispatch(setLoadingNotesState(true));
    }
    navigate("/home");
  };

  const showClientView = () => {
    const filterClient = document.querySelector(".filterClient");
    if (filterClient) {
      filterClient.classList.add("ant-select-dropdown-hidden");
    }
    dispatch(setCreateClientState(true));
    dispatch(setIsEditing(false));
    dispatch(resetClientForm());
    dispatch(setSelectedClient(null));
    dispatch(setNotesViewVisible(false));
    dispatch(setNotesDetails(null))
    dispatch(setCustomiseSectionState(false));
    dispatch(resetNotesDetails());
  };

  return (
    <Flex gap={10}>
      <Select
        showSearch
        placeholder="Select a client"
        optionFilterProp="children"
        onChange={selectClient}
        onClear={clearSelection}
        allowClear
        listHeight={isMobile ? 66 : 256}
        placement="bottomLeft"
        filterOption={(input, option) =>
          (option?.label.toLowerCase() ?? "").includes(input.toLowerCase())
        }
        popupClassName="filterClient"
        dropdownRender={(menu) => (
          <div className="client">
            {menu}
            <Divider style={{ margin: "4px 0" }} />
            <Flex gap={10}>
              <Button
                type="text"
                icon={<PlusOutlined />}
                onClick={showClientView}
              >
                Add Client
              </Button>
            </Flex>
          </div>
        )}
        style={{
          flexGrow: 1,
        }}
        options={clients.map((client) => ({
          label: client.name,
          value: client.id,
        }))}
      />
      <Button
        className={styles[`${componentName}__container-mobile-button`]}
        type="primary"
        onClick={showClientView}
        icon={<PlusOutlined />}
      >
        Add Client
      </Button>
    </Flex>
  );
};

export default ClientSelector;
