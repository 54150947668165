
type Props = {
  category_type?: number;
};

export default function CategoryType({ category_type }: Props) {
  const style = { color: "#1E3A8A", fontSize: "14px", fontWeight: "500" };
  if (category_type === 0) return <span style={style}>Individual</span>;
  else if (category_type === 1) return <span style={style}>Relationship</span>;
  else if (category_type === 2) return <span style={style}>Family</span>;
  else if (category_type === 3) return <span style={style}>Group</span>;
  else return <></>;
}
