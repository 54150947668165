import { Badge } from "antd";
import React from "react";
import styles from "./badge.module.scss";

type TProps = {
  count: string;
  selected?: boolean;
  disabled?: boolean;
  icon?: React.JSX.Element;
  onClick: () => void;
};
const SelectableBadge = ({
  count,
  selected,
  icon,
  onClick,
  disabled,
}: TProps) => {
  const componentName = "badge";
  return (
    <span
      onClick={() => {
        if (!disabled) onClick();
      }}
    >
      <Badge
        count={
          <>
            {icon}
            {count}
          </>
        }
        className={
          disabled && selected
            ? `${styles[`${componentName}-badge-selected-disabled`]}`
            : disabled && !selected
              ? `${styles[`${componentName}-badge-disabled`]}`
              : selected
                ? `${styles[`${componentName}-badge-selected`]}`
                : `${styles[`${componentName}-badge`]}`
        }
      />
    </span>
  );
};

SelectableBadge.defaultProps = {
  selected: false,
};

export default SelectableBadge;
