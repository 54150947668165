import { CheckCircleFilled } from "@ant-design/icons";
import { Typography } from "@mui/material";
import { Button, Checkbox, Col, Flex, Form, Input, Row } from "antd";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import HippaLogo from "../../assets/bg/hippa.png";
import LoadingModal from "../../components/LoadingModal";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import { setIsDemoPath } from "../../slices/appStateSlice";
import { register } from "../../slices/authSlice";
import { fetchUserData } from "../../slices/userSlice";
import { EventType, identifyUser, trackEvent } from "../../utils/analytics";
import RightSideAuth from "./auth-right-side.component";
import styles from "./auth.module.scss";

type RegisterProps = {
  singleGrid?: boolean;
}

const Register: React.FC<RegisterProps> = ({ singleGrid = false }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const componentName = "login";
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { isDemoPath } = useAppSelector((state) => state.appState);

  const handleRegister = async () => {
    setIsLoading(true);

    const { name, email, password } = form.getFieldsValue();

    try {
      const registerationSuccessful = await dispatch(
        register({
          name,
          email,
          password,
        }),
      ).unwrap();

      if (registerationSuccessful) {
        dispatch(setIsDemoPath(false));
        identifyUser(email, { email });
        trackEvent(EventType.REGISTER, { email });
        dispatch(fetchUserData());
        const token = localStorage.getItem("token");
        if (!token) {
          setIsLoading(false);
          return;
        }
        navigate("/home");
      }
    } catch (e) {
      console.error(e);
    }

    setIsLoading(false);
  };

  const inputElementFocused =
    (event: any) => {
      if (isDemoPath) {
        const { id } = event.target;
        if (id === "name") {
          trackEvent(EventType.CLICKED_ON_FULL_NAME_DEMO_PATH);
        } else if (id === "email") {
          trackEvent(EventType.CLICKED_ON_EMAIL_DEMO_PATH);
        } else if (id === "password") {
          trackEvent(EventType.CLICKED_ON_PASSWORD_DEMO_PATH);
        }
      }
    };

  return (
    <>
      {isLoading && <LoadingModal />}
      <Row
        className="fullScreen"
        style={singleGrid ? { maxWidth: "100%" } : {}}
      >
        <Col
          sm={24}
          md={24}
          lg={singleGrid ? 24 : 12}
          className={styles[`${componentName}__left`]}
        >
          <Flex
            className={styles[`${componentName}__form`]}
            vertical
            justify="center"
          >
            <Flex justify="center">
              <Flex className={styles[`${componentName}__form-badge`]}>
                <CheckCircleFilled
                  className={styles[`${componentName}__form-badge-icon`]}
                />
                Free to start, no card needed
              </Flex>
            </Flex>
            <Typography
              fontWeight={500}
              fontSize={30}
              variant="h5"
              textAlign={"center"}
              margin={1}
            >
              Welcome to Nudge
            </Typography>
            <Form
              className={styles[`${componentName}__form-container`]}
              form={form}
              layout="vertical"
              onFinish={handleRegister}
              autoComplete="off"
            >
              <Form.Item
                name="name"
                label="Full Name"
                rules={[{ required: true, message: "Please enter your name." }]}
              >
                <Input
                  placeholder="Susan Johnson"
                  onFocus={inputElementFocused}
                />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your email.",
                  },
                  {
                    pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                    message: "Oops, invalid email address, please try again.",
                  },
                ]}
              >
                <Input
                  placeholder="susan@example.com"
                  onFocus={inputElementFocused}
                />
              </Form.Item>
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  { required: true, message: "Please enter your password." },
                  {
                    min: 5,
                    message: "Password must be at least 5 characters long",
                  },
                ]}
              >
                <Input.Password
                  placeholder="Enter password"
                  type="password"
                  onFocus={inputElementFocused}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "100%", marginTop: "10px" }}
                >
                  Register for Free
                </Button>
              </Form.Item>
              <Form.Item
                name="agreement"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error("Please accept our terms of service."),
                          ),
                  },
                ]}
              >
                <Checkbox
                  className={
                    styles[`${componentName}__form-container-checkbox`]
                  }
                >
                  By signing up or logging into Nudge, you are agreeing to our 
                  <NavLink to={"/privacy"}> Privacy Policy</NavLink> and
                  <NavLink to={"/terms"}> Terms of Service</NavLink>.
                </Checkbox>
              </Form.Item>
            </Form>
            <Flex
              className={styles[`${componentName}__form-container-text`]}
              justify="center"
              style={{
                marginTop: "0px",
              }}
            >
              Already a User? &nbsp;<NavLink to={"/login"}>Login</NavLink>
            </Flex>
            <div
              className={
                styles[`${componentName}__form-container-bottom-notification`]
              }
            >
              Nudge is HIPAA compliant and redacts PHI. <br /> Nudge removes any
              personally identifying data, including audio.
            </div>
            <img
              src={HippaLogo}
              alt="Hippa"
              className={styles[`${componentName}__side-image-hippa`]}
            />
          </Flex>
        </Col>
        {!singleGrid && <RightSideAuth />}
      </Row>
    </>
  );
};

export default Register;
