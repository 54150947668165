import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TNotes } from "../types/index.type";

// Define a type for the slice state
type NotesState = {
  recordingId: number | undefined;
  notesDetails: TNotes | null;
  intakeNotes: string;
  eapIntakeNotes: string;
  psychIntakeNotes: string;
  psychFollowupNote: string;
  emdrNotes: string;
  emailContent: string;
  activeKey: string;
  solPsychIntakeNotes: string;
  solPsychFollowupNotes: string;
  solTherapyIntakeNotes: string;
  solTherapyFollowupNotes: string;
}

// Initial state
const initialState: NotesState = {
  recordingId: undefined,
  notesDetails: null,
  intakeNotes: "",
  eapIntakeNotes: "",
  psychIntakeNotes: "",
  psychFollowupNote: "",
  emdrNotes: "",
  emailContent: "",
  activeKey: "1",
  solPsychIntakeNotes: "",
  solPsychFollowupNotes: "",
  solTherapyIntakeNotes: "",
  solTherapyFollowupNotes: "",
};

// Slice
const notesSlice = createSlice({
  name: "notes",
  initialState,
  reducers: {
    setRecordingId: (state, action: PayloadAction<number>) => {
      state.recordingId = action.payload;
    },
    setNotesDetails: (state, action: PayloadAction<TNotes | null>) => {
      state.notesDetails = action.payload;
    },
    setIntakeNotes: (state, action: PayloadAction<string>) => {
      state.intakeNotes = action.payload;
    },
    setEapIntakeNotes: (state, action: PayloadAction<string>) => {
      state.eapIntakeNotes = action.payload;
    },
    setPsychIntakeNotes: (state, action: PayloadAction<string>) => {
      state.psychIntakeNotes = action.payload;
    },
    setPsychFollowupNote: (state, action: PayloadAction<string>) => {
      state.psychFollowupNote = action.payload;
    },
    setEmdrNotes: (state, action: PayloadAction<string>) => {
      state.emdrNotes = action.payload;
    },
    setSolPsychIntakeNotes: (state, action: PayloadAction<string>) => {
      state.solPsychIntakeNotes = action.payload;
    },
    setSolPsychFollowupNotes: (state, action: PayloadAction<string>) => {
      state.solPsychFollowupNotes = action.payload;
    },
    setSolTherapyIntakeNotes: (state, action: PayloadAction<string>) => {
      state.solTherapyIntakeNotes = action.payload;
    },
    setSolTherapyFollowupNotes: (state, action: PayloadAction<string>) => {
      state.solTherapyFollowupNotes = action.payload;
    },
    setEmailContent: (state, action: PayloadAction<string>) => {
      state.emailContent = action.payload;
    },
    setActiveKey: (state, action: PayloadAction<string>) => {
      state.activeKey = action.payload;
    },
    resetNotesDetails: (state) => {
      state.recordingId = undefined;
      state.notesDetails = null;
      state.intakeNotes = "";
      state.eapIntakeNotes = "";
      state.psychIntakeNotes = "";
      state.emdrNotes = "";
      state.emailContent = "";
      state.activeKey = "1";
      state.solPsychIntakeNotes = "";
      state.solPsychFollowupNotes = "";
      state.solTherapyIntakeNotes = "";
      state.solTherapyFollowupNotes = "";
    },
  },
});

// Actions
export const {
  setRecordingId,
  setNotesDetails,
  setIntakeNotes,
  setEapIntakeNotes,
  setPsychIntakeNotes,
  setPsychFollowupNote,
  setEmdrNotes,
  resetNotesDetails,
  setEmailContent,
  setActiveKey,
  setSolPsychIntakeNotes,
  setSolPsychFollowupNotes,
  setSolTherapyIntakeNotes,
  setSolTherapyFollowupNotes,
} = notesSlice.actions;

// Reducer
export default notesSlice.reducer;
