import {
  DownOutlined
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Collapse,
  Dropdown,
  Flex,
  type MenuProps,
  Slider,
  type SliderSingleProps,
  Space,
  message
} from "antd";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/redux-hooks";
import {
  getCustomizationList,
  handleWriteNoteSubmission,
} from "../../service/notes.service";
import {
  setCustomiseSectionState,
  setNotesViewVisible,
  setShowDemoSignupModal,
} from "../../slices/appStateSlice";
import { setCustomizationList } from "../../slices/clientSlice";
import { resetNotesDetails, setNotesDetails } from "../../slices/notesSlice";
import { ModalityMode, TNotes } from "../../types/index.type";
import styles from "./customise-note.module.scss";
import { getNotesType, NoteType } from "../../domain/notes.domain";

const formatter: NonNullable<SliderSingleProps["tooltip"]>["formatter"] = (
  value
) => {
  if (value === 0) {
    return "Low";
  }
  if (value === 1) {
    return "Default";
  }
  return "High";
};

const formatterFormatting: NonNullable<
  SliderSingleProps["tooltip"]
>["formatter"] = (value) => {
  if (value === 0) {
    return "Bullet";
  }
  if (value === 1) {
    return "Paragraph";
  }
  return "Numbered";
};

type TProps = {
  noPadding?: boolean;
};

const CustomiseNote: React.FC<TProps> = ({ noPadding }: TProps) => {
  const componentName = "customise-note";
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [settings, setSettings] = React.useState({
    note_section: "all",
    is_system: false,
    detail_level: 1,
    formatting: 1,
  });
  const { notesDetails } = useAppSelector((state) => state.notes);
  const { clients } = useAppSelector((state) => state.client);
  const { isDemoPath, currentPage } = useAppSelector(
    (state) => state.appState
  );
  const { customizationList } = useAppSelector((state) => state.client);

  const SOAP_ITEMS = [
    {
      key: "1",
      value: "all",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "all" }));
          }}
        >
          Entire Note
        </div>
      ),
    },
    {
      key: "2",
      value: "subjective",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "subjective" }));
          }}
        >
          Subjective Only
        </div>
      ),
    },
    {
      key: "3",
      value: "objective",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "objective" }));
          }}
        >
          Objective Only
        </div>
      ),
    },
    {
      key: "4",
      value: "assessment",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "assessment" }));
          }}
        >
          Assessment Only
        </div>
      ),
    },
    {
      key: "5",
      value: "plan",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "plan" }));
          }}
        >
          Plan Only
        </div>
      ),
    },
  ];

  const DAP_ITEM = [
    {
      key: "1",
      value: "all",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "all" }));
          }}
        >
          Entire Note
        </div>
      ),
    },
    {
      key: "2",
      value: "data",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "data" }));
          }}
        >
          Data Only
        </div>
      ),
    },
    {
      key: "3",
      value: "assessment",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "assessment" }));
          }}
        >
          Assessment Only
        </div>
      ),
    },
    {
      key: "4",
      value: "plan",
      label: (
        <div
          onClick={() => {
            setSettings((prev) => ({ ...prev, note_section: "plan" }));
          }}
        >
          Plan Only
        </div>
      ),
    },
  ];


  const items: MenuProps["items"] = notesDetails?.note_type ===  NoteType.SOAP ? SOAP_ITEMS : DAP_ITEM;
  
  const marks: SliderSingleProps["marks"] = {
    0: "Low",
    1: "Default",
    2: "High",
  };
  const marksFormatting: SliderSingleProps["marks"] = {
    0: "Bullet",
    1: "Paragraph",
    2: "Numbered",
  };

  const customize = () => {
    if (isDemoPath) {
      dispatch(setShowDemoSignupModal(true));
      dispatch(setCustomiseSectionState(false));
      return;
    }
    if (notesDetails) {
      const clientUuid = clients.find(
        (c) => c.id === notesDetails.client
      )?.uuid;
      handleWriteNoteSubmission({
        audio_id: notesDetails?.id,
        title: notesDetails.title,
        note_type: notesDetails.note_type,
        category_type: notesDetails.category_type,
        modality_mode: ModalityMode[notesDetails.modality_type],
        gender_type: notesDetails.gender_type,
        client_id: notesDetails.client,
        location_type: notesDetails.location_type,
        duration_type: notesDetails.duration_type,
        duration_addon_type: notesDetails.duration_addon_type,
        customization: {
          customization_type: notesDetails?.note_type ===  NoteType.SOAP ? 0 : 1,
          is_system: settings.is_system,
          note_section: settings.note_section,
          detail_level: settings.detail_level,
          formatting: settings.formatting,
        },
      })
        .then(() => {
          if (clientUuid) {
            navigate(`/home/clients/${clientUuid}`);
          } else {
            navigate("/home");
          }
          dispatch(setCustomiseSectionState(false));
          dispatch(setNotesViewVisible(false));
          dispatch(setNotesDetails(null));
          dispatch(setCustomiseSectionState(false));
          dispatch(resetNotesDetails());
          getCustomizationList()
            .then((response) => {
              const customization = response.data;
              dispatch(setCustomizationList(customization));
            })
            .catch((error) => {
              console.error("Error fetching customization list:", error);
            });
          message.success("Regenerating Note with custom configuration");
          if (settings.is_system) {
            message.success("Custom configuration saved for all future notes");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getSelection = () => {
    const options = notesDetails?.note_type ===  NoteType.SOAP ? SOAP_ITEMS : DAP_ITEM;
    return (
      <>
        {options.find((item) => item.value === settings.note_section)?.label ||
          ""}
      </>
    );
  };

  const restoreExistingSettings = useCallback(() => {
    if (customizationList.length) {
      const type = notesDetails?.note_type ===  NoteType.SOAP ? 0 : 1;
      const existingSettings = customizationList.find(
        (item) => item.customization_type === type
      );
      if (existingSettings) {
        setSettings({
          note_section: existingSettings.customization.note_section,
          is_system: existingSettings.customization.is_system,
          detail_level: existingSettings.customization.detail_level,
          formatting: existingSettings.customization.formatting,
        });
      }
    }
  }, [customizationList, notesDetails]);

  React.useEffect(() => {
    restoreExistingSettings();
  }, [restoreExistingSettings]);

  if (!notesDetails) {
    return <></>;
  }

  return (
    <Flex
      vertical
      gap={20}
      style={{
        padding: noPadding ? "0px" : "20px",
      }}
    >
      <Flex>
        <Dropdown menu={{ items }}>
          <Button onClick={(e) => e.preventDefault()}>
            <Space>
              Apply to <span>{getSelection()}</span>
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      </Flex>
      <Flex vertical>
        <Flex vertical>
          <Collapse
            className={styles[`${componentName}__collapse`]}
            items={[
              {
                key: "1",
                label: "Detail level",
                extra: <b>{formatter(settings.detail_level)}</b>,
                children: (
                  <Flex
                    vertical
                    style={{
                      padding: "0px 20px",
                    }}
                  >
                    <Slider
                      marks={marks}
                      min={0}
                      max={2}
                      value={settings.detail_level}
                      onChange={(value) =>
                        setSettings((prev) => ({
                          ...prev,
                          detail_level: value,
                        }))
                      }
                      tooltip={{ formatter }}
                      styles={{
                        track: {
                          backgroundColor: "#6366F1",
                        },
                      }}
                    />
                    {/* <Divider />
                      <Flex vertical>
                        <Flex gap={10}>
                          <QuestionCircleOutlined />
                          What's this
                        </Flex>
                        <Flex
                          style={{
                            marginLeft: "25px",
                          }}
                        >
                          Lorum Ipsum
                        </Flex>
                      </Flex> */}
                  </Flex>
                ),
              },
            ]}
          />
        </Flex>
      </Flex>
      <Flex>
        <Collapse
          className={styles[`${componentName}__collapse`]}
          items={[
            {
              key: "1",
              extra: <b>{formatterFormatting(settings.formatting)}</b>,
              label: "Formatting style",
              children: (
                <Flex
                  vertical
                  style={{
                    padding: "0px 20px",
                  }}
                >
                  <Slider
                    marks={marksFormatting}
                    value={settings.formatting}
                    onChange={(value) =>
                      setSettings((prev) => ({ ...prev, formatting: value }))
                    }
                    min={0}
                    max={2}
                    tooltip={{ formatter: formatterFormatting }}
                    styles={{
                      track: {
                        backgroundColor: "#6366F1",
                      },
                    }}
                  />
                  {/* <Divider />
                    <Flex vertical>
                      <Flex gap={10}>
                        <QuestionCircleOutlined />
                        What's this
                      </Flex>
                      <Flex
                        style={{
                          marginLeft: "25px",
                        }}
                      >
                        Lorum Ipsum
                      </Flex>
                    </Flex> */}
                </Flex>
              ),
            },
          ]}
        />
      </Flex>
      <Flex
        style={{
          backgroundColor: "var(--grey-bg)",
          padding: "10px",
          borderRadius: "5px",
        }}
      >
        <Checkbox
          onChange={(e) =>
            setSettings((prev) => ({ ...prev, is_system: e.target.checked }))
          }
          className={styles[`${componentName}__collapse-checkbox`]}
          checked={settings.is_system}
        >
          Save configuration for future {getNotesType(notesDetails.note_type)}{" "}
          notes.
        </Checkbox>
      </Flex>
      <Flex>
        <Button
          className={styles[`${componentName}__collapse-save`]}
          type="primary"
          size="middle"
          onClick={customize}
        >
          Regenerate Note
        </Button>
      </Flex>
    </Flex>
  );
};

export default CustomiseNote;
